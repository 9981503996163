import { render, staticRenderFns } from "./LeftBident.vue?vue&type=template&id=62c2be9b&scoped=true&"
import script from "./LeftBident.vue?vue&type=script&lang=js&"
export * from "./LeftBident.vue?vue&type=script&lang=js&"
import style0 from "./LeftBident.vue?vue&type=style&index=0&id=62c2be9b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c2be9b",
  null
  
)

export default component.exports