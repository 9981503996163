<template>
  <div class="bident">
    <div class="semi-circle"></div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
 name: 'left-bident'  
};
</script>

<style lang="scss" scoped>
$bident-color: #7DB4E2;

.bident {
  position: absolute;
  height: max-content;
  // top: 20%;
  // left: 25%;
  // border: 1px solid blue;
  
  .semi-circle {
    $circle-borders: 50px;
    $circle-size: 100px;
    background-color: transparent;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    border: 5px solid $bident-color;
    // box-shadow: 0px 0px $circle-borders #EDE7F6, inset 0px 0px $circle-borders #EDE7F6;
    position: relative;
    left: -40px;
    
      &::after {
        content: '';
        background-color: white;
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: 50px;
      }
  }

   .line{
  width: 110px;
  border-bottom: 5px solid $bident-color;
  position: absolute;
  top: 50%;
  left: 60%;
  }
}  
</style>
