<template>
  <div class="circle"></div>
</template>

<script>
export default {
  name: "semi-circle"
};
</script>

<style lang="scss" scoped>
.circle {
  $circle-borders: 50px;
  $circle-size: 200px;
  background-color: transparent;
  width: $circle-size;
  height: $circle-size;
  border-radius: 50%;
  border: 5px solid #BBDEFB;
  box-shadow: 0px 0px $circle-borders #EDE7F6, inset 0px 0px $circle-borders #EDE7F6;
  position: relative;

  &::after {
    content: '';
    background-color: white;
    position: absolute;
    top: -$circle-borders;
    bottom: -$circle-borders;;
    left: 100px;
    right: -$circle-borders;
  }
}
</style>
