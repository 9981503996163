<template>
  <div class="components page">
    <h1>All Components</h1>
    <hr>
    <div class="item-component">
      <h2>Rounded Button</h2>
      <p>props: text (required)</p>
      <Button text="Get to Know more about our product"></Button>
    </div>

    <hr>
    <div class="item-component">
      <h2>Download Button</h2>
      <p>props: platform (ios/andriod)</p>
      <Dlbutton platform="andriod"></Dlbutton>
    </div>

    <hr>
    <div class="item-component">
      <h2>TextBlock</h2>
      <p>Props: title, description, icon</p>
      <TextBlock
        title="Transparent Disclosure"
        description="In order to protect the rights and interests of public users and provide
          a truly open, fair and transparent trading environment, we open the
          exchange assets and transaction data in real time, and invite the public
          to conduct supervision and verification"
      />
    </div>

    <hr>
    <div class="item-component">
      <h2>Text-block 2</h2>
      <p>props: number, title, subtitle, description</p>
      <TextBlock2
        class="textblock2"
        number="01."
        title="szgDemo.title1"
        subtitle="szgDemo.subtitle1"
        description="szgDemo.description1"
      />
    </div>

    <hr>
    <div class="item-component">
      <h2>HighlightCircles</h2>
      <p>props: title, description</p>
      <HighlightCircles title="highlights.title1" description="highlights.msg1"/>
    </div>

    <hr>
    <div class="item-component">
      <h2>Semi circle</h2>
      <p>props: none</p>
      <SemiCircle/>
    </div>

    <hr>
    <div class="item-component">
      <h2>Pulsing Circle</h2>
      <p>props: none</p>
      <PulsingCircle/>
    </div>

    <hr>
    <div class="item-component">
      <h2>Bident</h2>
      <p>props: none</p>
      <Bident/>
    </div>

    <hr>
    <div class="item-component">
      <h2>SZG Card</h2>
      <p>
        props: title, description,
        <br>icon(only accept registered component name): ConsumerIcon, RobustIcon, SecurityIcon
      </p>
      <Card title="szgCard.title1" description="szgCard.description1" icon="ConsumerIcon"/>
    </div>

  </div>
</template>

<script>
import Button from "@/components/partials/RoundedButton.vue";
import Dlbutton from "@/components/partials/DownloadBtn.vue";
import TextBlock from "@/components/partials/TextBlock.vue";
import TextBlock2 from "@/components/partials/TextBlock2.vue";
import HighlightCircles from "@/components/partials/HighlightCircles.vue";
import SemiCircle from "@/components/partials/SemiCircle.vue";
import PulsingCircle from "@/components/partials/PulsingCircle.vue";
import Bident from "@/components/partials/LeftBident.vue";
import Card from "@/components/partials/Card.vue";

export default {
  name: "components",
  head: {
    title: { inner: "Components Showcase" }
  },
  components: {
    Button,
    Dlbutton,
    TextBlock,
    TextBlock2,
    HighlightCircles,
    SemiCircle,
    PulsingCircle,
    Bident,
    Card
  }
};
</script>

<style lang="scss" scoped>
.item-component {
  position: relative;
  min-height: 200px;
  .textblock2 {
    width: 400px;
  }
}
</style>

