import { render, staticRenderFns } from "./SemiCircle.vue?vue&type=template&id=a91aee60&scoped=true&"
import script from "./SemiCircle.vue?vue&type=script&lang=js&"
export * from "./SemiCircle.vue?vue&type=script&lang=js&"
import style0 from "./SemiCircle.vue?vue&type=style&index=0&id=a91aee60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a91aee60",
  null
  
)

export default component.exports